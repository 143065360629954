import {match} from 'ts-pattern';

import {isNotNil} from 'ramda';

export const getLineChartLabelDy = (
  index: number,
  type: 'sold' | 'stock',
  chartHeight: number,
  chartData?: {sold: number | null; month: string; stock: number | null}[]
) => {
  /* highestValue and lowestValue is not always maximum and minimum of the grid.
     It means this computation is inaccurate. However, to prevent labels from colliding it is good enough */
  const highestValue = Math.max(
    ...(chartData?.map((item) => item.sold).filter(isNotNil) ?? []),
    ...(chartData?.map((item) => item.stock).filter(isNotNil) ?? [])
  );
  const lowestValue = Math.min(
    ...(chartData?.map((item) => item.sold).filter(isNotNil) ?? []),
    ...(chartData?.map((item) => item.stock).filter(isNotNil) ?? [])
  );
  const valueRange = highestValue - lowestValue;
  const rangePerPixel = valueRange / (chartHeight - 40); /* 40 = bottom legend height */
  const labelHeight = 12;
  const offset = 6;
  const areLabelsColliding =
    Math.abs((chartData?.[index].sold ?? 0) - (chartData?.[index].stock ?? 0)) / rangePerPixel <
    labelHeight + offset;
  const isSoldHigher = (chartData?.[index].sold ?? 0) > (chartData?.[index].stock ?? 0);
  return match([areLabelsColliding, type, isSoldHigher])
    .with([true, 'sold', false], () => 12)
    .with([true, 'stock', true], () => 12)
    .otherwise(() => -6);
};
