import {isNil} from 'ramda';

import {useQueryState} from 'shared';

import {QueryStateKeys} from '../../../../../utils/routing';

export const useSelectedVehicleIdQueryState = () => {
  const [selectedVehicleId, _setSelectedVehicleId, _clearSelectedVehicleId] = useQueryState(
    QueryStateKeys.StatisticsSelectedVehicleId,
    true
  );
  const setSelectedVehicleId = (vehicleId: string | null) => {
    isNil(vehicleId) ? _clearSelectedVehicleId() : _setSelectedVehicleId(vehicleId);
  };

  return [selectedVehicleId, setSelectedVehicleId] as const;
};
