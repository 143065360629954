import {Card, DataStatus, Form, FormSubmitHandler, showNotification} from 'platform/components';
import {Box, HStack, Show, Space, VStack} from 'platform/foundation';
import {NumberFormatter, useFormatNumber} from 'platform/locale';
import {array, object} from 'yup';

import {useCallback, useEffect, useState} from 'react';
import {FieldErrors} from 'react-hook-form';
import {Navigate, useParams} from 'react-router-dom';

import {not} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {i18n} from '@price-report/shared';

import {
  debounce,
  suffixTestId,
  TestIdProps,
  useBoolean,
  useNavigate,
  yupNumber,
  yupString,
} from 'shared';

import {validateVin} from 'features/vin-validate';

import {PageBackgroundGradient} from '../../components/PageBackgroundGradient/PageBackgroundGradient';
import {StickyContainer} from '../../components/StickyContainer/StickyContainer';
import {
  useCreatePriceReportFromRequestMutation,
  useGetRequestCountsQuery,
  useGetRequestQuery,
  useUpdateRequestMutation,
} from '../../store/priceReportApi';
import {getOverviewPath, overviewRoute} from '../../utils/routing';
import {BottomActions} from './components/BottomActions';
import {FeatureLevelSidebar} from './components/FeatureLevelSidebar';
import {Stepper} from './components/Stepper';
import {VehicleFeatures} from './components/VehicleFeatures';
import {VehicleFields} from './components/VehicleFields';
import {VehicleHeader} from './components/VehicleHeader';
import {WelcomeSidebar} from './components/WelcomeSidebar';
import {MIN_FIRST_REGISTRATION_YEAR} from './constants/minFirstRegistrationYear';
import {VehicleFormType} from './types/VehicleFormType';
import {getDefaultValues} from './utils/getDefaultValues';
import {useGetRequestVehicleFromFormData} from './utils/useGetRequestVehicleFromFormData';

const DEBOUNCED_TIME = 1000;

export function CreatePage(props: TestIdProps) {
  const params = useParams();
  const navigate = useNavigate();
  const getRequestVehicleFromFormData = useGetRequestVehicleFromFormData();

  const {data: request, isError, isLoading} = useGetRequestQuery({id: params.requestId ?? ''});
  const {
    data: requestCounts,
    isLoading: isRequestCountsLoading,
    isFetching: isRequestCountsFetching,
  } = useGetRequestCountsQuery({
    id: params.requestId ?? '',
  });
  const [createPriceReport] = useCreatePriceReportFromRequestMutation();
  const [updateRequest, {isLoading: isUpdateRequestLoading}] = useUpdateRequestMutation();
  const [isChanged, setChanged, setNotChanged] = useBoolean();
  const formatNumber = useFormatNumber();

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (not(isRequestCountsLoading || isRequestCountsFetching || isUpdateRequestLoading)) {
      setNotChanged();
    }
  }, [isRequestCountsLoading, isRequestCountsFetching, isUpdateRequestLoading, setNotChanged]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const onSubmit: FormSubmitHandler<VehicleFormType> = async (data) => {
    await updateRequest({
      id: params.requestId ?? '',
      body: {vehicle: getRequestVehicleFromFormData(data)},
    })
      .unwrap()
      .catch(() => showNotification.error(i18n.t('general.labels.error')));

    if (step === 0) {
      return setStep(1);
    }

    await createPriceReport({body: {idRequest: params.requestId ?? ''}})
      .unwrap()
      .then(() => navigate(getOverviewPath(params)))
      .catch(() => showNotification.error(i18n.t('general.labels.error')));
  };

  const onChange = useCallback(
    (data: VehicleFormType) => {
      updateRequest({
        id: params.requestId ?? '',
        body: {vehicle: getRequestVehicleFromFormData(data)},
      })
        .unwrap()
        .catch(() => showNotification.error(i18n.t('general.labels.error')));
    },
    [updateRequest, params.requestId, getRequestVehicleFromFormData]
  );

  // Dependencies are provided, inline function broke debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(debounce(onChange, DEBOUNCED_TIME), [onChange]);

  const handleChanged = (data: VehicleFormType) => {
    setChanged();
    debouncedOnChange(data);
  };

  const handleInvalidSubmit = (errors: FieldErrors<VehicleFormType>) => {
    const errorNames = Object.keys(errors);
    if (errorNames.length > 0) {
      const input = document.querySelector(`[data-name=${errorNames[0]}]`);
      input?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  };

  const formSchema = (formatNumber: NumberFormatter) =>
    object({
      make: yupString.required(),
      modelFamily: yupString.required(),
      vin: yupString.test('validateVin', i18n.t('page.create.validation.vinIsInvalid'), (vin) =>
        isNilOrEmpty(vin) ? true : validateVin(vin ?? '')
      ),
      firstRegistrationOnYear: yupNumber
        .min(MIN_FIRST_REGISTRATION_YEAR, ({min}) =>
          i18n.t('page.create.validation.firstRegistrationOnYearMustBeAtLeast', {
            year: min,
          })
        )
        .required(),
      transmission: array().of(yupString.required()).required(),
      fuelType: array().of(yupString.required()).required(),
      bodyStyle: array().of(yupString.required()).required(),
      drive: array().of(yupString.required()).required(),
      power: yupNumber
        .integer(i18n.t('page.create.validation.powerMustBeInteger'))
        .min(10, ({min}) => i18n.t('page.create.validation.powerMustBeMin', {min}))
        .max(650, ({max}) => i18n.t('page.create.validation.powerMustBeMax', {max}))
        .required(),
      exteriorColor: array().of(yupString.required()),
      interiorMaterial: array().of(yupString.required()),
      isVatDeductible: array().of(yupString.required()),
      doorCount: array().of(yupString.required()),
      seatCount: array().of(yupString.required()),
      mileage: yupNumber
        .min(0, i18n.t('page.create.validation.mileageCantBeNegative'))
        .max(350_000, ({max}) =>
          i18n.t('page.create.validation.mileageMustBeMax', {max: formatNumber(max)})
        )
        .required(),
      features: array().of(yupString.required()),
    });

  const count = requestCounts && (requestCounts.sold ?? 0) + (requestCounts.stock ?? 0);

  return (
    <PageBackgroundGradient>
      <DataStatus isLoading={isLoading} isError={isError}>
        {request?.idPriceReport && <Navigate to={overviewRoute} />}
        <VStack shrink={1} maxWidth={315} width="100%">
          <Form<VehicleFormType>
            defaultValues={getDefaultValues(request?.vehicle)}
            schema={formSchema(formatNumber)}
            onSubmit={onSubmit}
            onChange={handleChanged}
            onInvalidSubmit={handleInvalidSubmit}
          >
            {(control, formApi) => (
              <>
                <Stepper
                  steps={[
                    i18n.t('page.create.labels.stepSpecification'),
                    i18n.t('page.create.labels.stepFeatures'),
                    i18n.t('page.create.labels.stepPricing'),
                  ]}
                  value={step}
                />
                <Space vertical={6} />
                <VehicleHeader
                  formApi={formApi}
                  data-testid={suffixTestId('vehicleHeader', props)}
                />
                <Space vertical={6} />
                <HStack spacing={6} justify="center" wrap={[true, false, false, false]}>
                  <Box flex={1}>
                    <Card>
                      <Box padding={2}>
                        <Show when={step === 0}>
                          <VehicleFields
                            control={control}
                            formApi={formApi}
                            count={count}
                            soldCount={requestCounts?.sold}
                            data-testid={suffixTestId('vehicleFields', props)}
                          />
                        </Show>
                        <Show when={step === 1}>
                          <VehicleFeatures
                            control={control}
                            formApi={formApi}
                            data-testid={suffixTestId('vehicleFeatures', props)}
                          />
                        </Show>
                      </Box>
                    </Card>
                  </Box>
                  <Box width={['100%', 80, 80, 108]} flexShrink={0}>
                    <StickyContainer offset={2}>
                      <Card>
                        <Box padding={2}>
                          <Show when={step === 0}>
                            <WelcomeSidebar
                              requestId={params.requestId}
                              isUpdateRequestLoading={isUpdateRequestLoading}
                              isChanged={isChanged}
                              data-testid={suffixTestId('welcomeSidebar', props)}
                            />
                          </Show>
                          <Show when={step === 1}>
                            <FeatureLevelSidebar
                              featureLevel={request?.featuresLevel ?? undefined}
                              isLoading={isLoading}
                              isError={isError}
                            />
                          </Show>
                        </Box>
                      </Card>
                    </StickyContainer>
                  </Box>
                </HStack>
                <BottomActions
                  step={step}
                  control={control}
                  formApi={formApi}
                  onBackClick={() => setStep(0)}
                  data-testid={suffixTestId('bottomActions', props)}
                />
              </>
            )}
          </Form>
        </VStack>
      </DataStatus>
    </PageBackgroundGradient>
  );
}
