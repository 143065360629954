import {createContext, ReactNode, useContext} from 'react';
import {useSearchParams} from 'react-router-dom';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {FilterFormData} from '@price-report/shared';

import {noop} from 'shared';

import {parseFilterFormDataToSearchParams} from '../utils/parseFilterFormDataToSearchParams';
import {parseSearchParamsToFilterFormData} from '../utils/parseSearchParamsToFilterFormData';

type SelectedFiltersContextType = {
  selectedFilters: FilterFormData | null;
  onSelectFilters: (data: FilterFormData | null) => void;
};

const SelectedFiltersContext = createContext<SelectedFiltersContextType>({
  selectedFilters: null,
  onSelectFilters: noop,
});

type SelectedFiltersProviderProps = {
  children: ReactNode;
};

const SelectedFiltersProvider = (props: SelectedFiltersProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onSelectFilters = (data: FilterFormData | null) => {
    const params = parseFilterFormDataToSearchParams(searchParams, data);
    setSearchParams(params, {replace: true});
  };

  const parsedFilters = parseSearchParamsToFilterFormData(searchParams);
  const areFiltersEmpty = Object.values(parsedFilters).some((filter) => isNotNilOrEmpty(filter));

  const context: SelectedFiltersContextType = {
    selectedFilters: areFiltersEmpty ? parsedFilters : null,
    onSelectFilters,
  };

  return (
    <SelectedFiltersContext.Provider value={context}>
      {props.children}
    </SelectedFiltersContext.Provider>
  );
};

const useSelectedFilters = () => useContext(SelectedFiltersContext);

export {SelectedFiltersProvider, useSelectedFilters};
