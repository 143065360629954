import {createApi} from '@reduxjs/toolkit/query/react';

import {
  DrillDownApiArg,
  DrillDownResponse,
  UserComplaintRequestBody,
  UserComplaintResponse,
} from '@price-report/shared';

import {fixAlfaBugsInDrillDownResponse} from '../utils/fixAlfaBugsInDrillDownResponse';
import {omneticBaseQuery} from './omneticBaseQuery';

export const omneticApi = createApi({
  reducerPath: 'omnetic',
  baseQuery: omneticBaseQuery,
  endpoints: (build) => ({
    drillDown: build.query<DrillDownResponse, DrillDownApiArg>({
      query: (queryArg) => ({
        url: `/dms/v2/sourcing/drill-down`,
        method: 'POST',
        body: queryArg,
        headers: {
          'X-Workspace': 'price-report-backend',
          'accept-language': queryArg.lang,
        },
      }),
      // TODO T20-30972 - fix response on BE
      transformResponse: (response: DrillDownResponse, meta, arg: DrillDownApiArg) =>
        fixAlfaBugsInDrillDownResponse(
          arg.levels.map((level) => level.level),
          response
        ),
    }),
    userComplaint: build.mutation<UserComplaintResponse, UserComplaintRequestBody>({
      query: (queryArg) => ({
        url: '/dms/v1/sales/price-report/user-complaint',
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
});

export const {useDrillDownQuery, useUserComplaintMutation} = omneticApi;
