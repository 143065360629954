import {generatePath} from 'react-router';
import {Params} from 'react-router-dom';

import {concat, join, map, omit, pipe, replace, trim} from 'ramda';

export enum QueryStateKeys {
  Debug = 'debug',
  StatisticsAvailabilities = 'availabilities',
  StatisticsSelectedVehicleId = 'selectedVehicleId',
  DrillDownOrder = 'order',
}

const searchParamsNotToPreserve = [
  QueryStateKeys.StatisticsAvailabilities,
  QueryStateKeys.StatisticsSelectedVehicleId,
  QueryStateKeys.DrillDownOrder,
];

const pathParamsNotToPreserve = ['page'];

const pathSeparator = '/';

const pathGenerator = (paths: string, params?: Params<string>, isSamePage?: boolean) => {
  const pathParams = omit(!isSamePage ? pathParamsNotToPreserve : [], params ?? {});
  const path = generatePath(paths, pathParams);

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  if (!isSamePage) {
    for (const key of searchParamsNotToPreserve) {
      searchParams.delete(key);
    }
  }
  const searchParamsString = searchParams.toString();

  return searchParamsString ? `${path}?${searchParamsString}` : path;
};
/**
 * Makes pathname from array of string
 */
export const makePath = pipe(
  map(trim),
  join(pathSeparator),
  concat(pathSeparator),
  replace(/\/{2,}/g, pathSeparator)
);

/**
 * Routes
 */
export const reportRoute = ':requestId';
export const overviewRoute = 'overview';
export const drillDownRoute = 'drill-down';
export const advertisementRoute = 'advertisement/:view?/:page?';
export const statisticsRoute = 'statistics/:chartType?/:page?';
export const createRoute = 'create';

/**
 * Routes
 */
export const overviewPath = makePath([reportRoute, overviewRoute]);
export const drillDownPath = makePath([reportRoute, drillDownRoute]);
export const advertisementPath = makePath([reportRoute, advertisementRoute]);
export const statisticsPath = makePath([reportRoute, statisticsRoute]);
export const createPath = makePath([reportRoute, createRoute]);

/**
 * Route generators
 */
export const getOverviewPath = (params?: Params<string>) => pathGenerator(overviewPath, params);
export const getDrillDownPath = (params?: Params<string>) => pathGenerator(drillDownPath, params);
export const getAdvertisementPath = (params?: Params<string>, isSamePage?: boolean) =>
  pathGenerator(advertisementPath, params, isSamePage);
export const getStatisticsPath = (params?: Params<string>, isSamePage?: boolean) =>
  pathGenerator(statisticsPath, params, isSamePage);
export const getCreatePath = (params?: Params<string>) => pathGenerator(createPath, params);
