import {Clickable, Icon} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';
import styled from 'styled-components';

import {FALLBACK_LANGUAGE, i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps, useBoolean, useQueryState} from 'shared';

import {languageDialogItems} from '../../../../../constants/languageDialogItems';
import {queryParams} from '../../../../../constants/queryParams';
import {LanguageDialogModal} from './LanguageDialogModal';

export function LanguageSwitcher(props: TestIdProps) {
  const [isVisible, open, close] = useBoolean(false);
  const [, setLanguage] = useQueryState(queryParams.LANG);

  const onChangeLanguage = (lang: AppLanguage) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        setLanguage(lang);
      })
      .catch(() => {
        throw new Error('Failed to set the application language');
      });
  };

  const flagIcon =
    languageDialogItems[(i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE) as AppLanguage].icon;

  return (
    <>
      <Clickable onClick={open}>
        <StyledFlag>
          <Icon value={flagIcon} size={10} />
        </StyledFlag>
      </Clickable>
      <LanguageDialogModal
        isOpen={isVisible}
        onChoseLanguage={onChangeLanguage}
        onClose={close}
        data-testid={suffixTestId('languageSwitcher', props)}
      />
    </>
  );
}

// eslint-disable-next-line eag/no-css-property
const StyledFlag = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: ${({theme}) => theme.getSize(6)};
  height: ${({theme}) => theme.getSize(6)};

  & > div {
    margin-top: calc(-1 * ${({theme}) => theme.getSize(2)});
    margin-left: calc(-1 * ${({theme}) => theme.getSize(2)});
  }
`;
