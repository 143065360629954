import {Show} from 'platform/foundation';
import styled from 'styled-components';

import {ChangeEvent, useId} from 'react';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {visuallyHiddenStyle} from '../../../constants/visuallyHiddenStyle';
import {FormControlProps} from '../../../types/FormControlProps';
import {callAllHandlers} from '../../../utils/callAllHandlers';
import {dataAttr} from '../../../utils/dataAttr';

export interface RadioItemProps extends FormControlProps<boolean>, TestIdProps {
  children?: string;
}

export function RadioItem(props: RadioItemProps) {
  const id = useId();

  const [isFocused, handleFocus, handleBlur] = useBoolean();
  const [isHovered, handleHover, handleLeave] = useBoolean();

  const isInvalid = props.isInvalid || !!props.errorMessage;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.isDisabled) {
      event.preventDefault();
      return;
    }

    props.onChange?.(props.value);
  };

  const rootProps = {
    'data-disabled': dataAttr(props.isDisabled),
    'data-checked': dataAttr(props.value),
    'data-invalid': dataAttr(isInvalid),
  };

  const checkboxProps = {
    'data-hover': dataAttr(isHovered),
    'data-checked': dataAttr(props.value),
    'data-disabled': dataAttr(props.isDisabled),
    'data-invalid': dataAttr(isInvalid),
    'data-focus': dataAttr(isFocused),
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  };

  return (
    <StyledLabel
      htmlFor={id}
      title={props.children}
      {...rootProps}
      data-testid={suffixTestId('radioWrapper', props)}
    >
      <input
        id={id}
        type="radio"
        onChange={handleChange}
        onFocus={callAllHandlers(props.onFocus, handleFocus)}
        onBlur={callAllHandlers(props.onBlur, handleBlur)}
        disabled={props.isDisabled}
        checked={props.value}
        required={props.isRequired}
        data-testid={suffixTestId('radioInput', props)}
        style={visuallyHiddenStyle}
      />
      <StyledRadioControl
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
        {...checkboxProps}
        data-testid={suffixTestId('radioControl', props)}
      />
      <Show when={props.children}>
        <StyledRadioLabel data-testid={suffixTestId('radioLabel', props)}>
          {props.children}
        </StyledRadioLabel>
      </Show>
    </StyledLabel>
  );
}

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledRadioControl = styled.span`
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  border-width: 1px;
  border-color: ${({theme}) => theme.colors.palettes.neutral[200][100]};
  height: 20px;
  width: 20px;
  border-radius: 50%;

  &[data-checked=''] {
    border-color: ${({theme}) => theme.colors.general.accent};
    border-width: 4px;

    &::before {
      width: 12px;
      height: 12px;
    }
  }

  &:focus {
    &:disabled {
      box-shadow: none;
    }
  }

  &[data-disabled=''] {
    background: transparent;
    border-width: 1px;
    border-color: ${({theme}) => theme.colors.palettes.neutral[200][100]};
    opacity: 0.4;

    &:checked {
      background-color: ${({theme}) => theme.colors.general.accent};
      color: ${({theme}) => theme.colors.palettes.white[10][100]};
      opacity: 0.4;
    }
  }
`;

const StyledRadioLabel = styled.span`
  font-size: ${({theme}) => theme.fontSizes.text.small};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  font-weight: ${({theme}) => theme.fontWeights.text.default};
  margin-left: 12px;
`;
