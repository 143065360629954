import {Dialog, IconButton} from 'platform/components';
import {Box, Clickable, Grid, HStack, Icon, Text, VStack} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';
import styled from 'styled-components';

import {i18n} from '@price-report/shared';

import {TestIdProps} from 'shared';

import {languageDialogItems} from '../../../../../constants/languageDialogItems';

interface LanguageDialogModalProps extends TestIdProps {
  isOpen: boolean;
  onChoseLanguage: (lang: AppLanguage) => void;
  onClose: () => void;
}

export function LanguageDialogModal({isOpen, onChoseLanguage, onClose}: LanguageDialogModalProps) {
  const handleChoseLanguage = (lang: AppLanguage) => {
    onChoseLanguage(lang);
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} disableBodyPadding>
      <Box padding={8}>
        <VStack spacing={8}>
          <HStack align="center" justify="space-between">
            <Text alternative size="large">
              {i18n.t('general.actions.chooseLanguage')}
            </Text>
            <IconButton icon="navigation/close" onClick={onClose} />
          </HStack>
          <Grid columns={2}>
            {Object.entries(languageDialogItems).map(([lang, item]) => (
              <Clickable key={item.title} onClick={() => handleChoseLanguage(lang as AppLanguage)}>
                <StyledLanguageButton $chosen={i18n.resolvedLanguage === lang}>
                  <HStack align="center" spacing={2}>
                    <Icon value={item.icon} size={5} />
                    <Text alternative>{item.title}</Text>
                  </HStack>
                </StyledLanguageButton>
              </Clickable>
            ))}
          </Grid>
        </VStack>
      </Box>
    </Dialog>
  );
}

const StyledLanguageButton = styled.div<{$chosen: boolean}>`
  border: 1px solid transparent;

  &:hover {
    border-color: ${({theme}) => theme.colors.palettes.blue[10][100]};
  }

  background-color: ${({theme, $chosen}) =>
    $chosen ? theme.colors.palettes.blue[10][100] : 'initial'};
  border-radius: ${({theme}) => theme.getSize(2)};
  padding: ${({theme}) => theme.getSize(4)};
`;
