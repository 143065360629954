import {Choice, Segment} from 'platform/components';
import {Box, useDevice} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {i18n} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {ChartVariant} from '../types/ChartVariant';

interface ChartTypeSwitchProps extends TestIdProps {
  chartVariant: ChartVariant;
  defaultChartType: ChartVariant;
  setChartVariant: (value: ChartVariant) => void;
}

export function ChartTypeSwitch(props: ChartTypeSwitchProps) {
  const device = useDevice();
  const isChartTypeSelectCompact = device !== 'desktop';

  const chartVariantOptions: Array<{value: ChartVariant; label: string}> = [
    {value: 'priceMap', label: i18n.t('entity.chartOptions.priceMap')},
    {value: 'price', label: i18n.t('entity.chartOptions.price')},
    {value: 'mileage', label: i18n.t('entity.chartOptions.mileage')},
    {value: 'daysOnStock', label: i18n.t('entity.chartOptions.daysOnStock')},
    {value: 'featuresLevel', label: i18n.t('entity.chartOptions.featuresLevel')},
  ];

  return isChartTypeSelectCompact ? (
    <Box width={['100%', '100%', 'auto', 'auto']}>
      <Choice
        value={props.chartVariant}
        options={chartVariantOptions}
        onChange={(value) =>
          props.setChartVariant(isNotNil(value) ? value : props.defaultChartType)
        }
        isNotClearable
        horizontalMenuPlacement="right"
        data-testid={suffixTestId('choice', props)}
      />
    </Box>
  ) : (
    <Segment
      value={props.chartVariant}
      options={chartVariantOptions}
      onChange={props.setChartVariant}
      data-testid={suffixTestId('segment', props)}
    />
  );
}
