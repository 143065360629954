import {useMemo} from 'react';

import {isNotEmpty} from 'ramda';

import {useQueryState} from 'shared';

import {QueryStateKeys} from '../../../../../utils/routing';
import {Availability} from '../types';

export const useVisibleAvailabilitiesQueryState = (defaultAvailabilities: Availability[]) => {
  const [_visibleAvailabilities, _setVisibleAvailabilities] = useQueryState(
    QueryStateKeys.StatisticsAvailabilities,
    true
  );
  const visibleAvailabilities: Availability[] = useMemo(
    () =>
      ((isNotEmpty(_visibleAvailabilities)
        ? _visibleAvailabilities?.split(',').sort()
        : []) as Availability[]) ?? defaultAvailabilities,
    [_visibleAvailabilities, defaultAvailabilities]
  );
  const setVisibleAvailabilities = (availabilities: Availability[]) => {
    _setVisibleAvailabilities(availabilities.toString());
  };

  return [visibleAvailabilities, setVisibleAvailabilities] as const;
};
