import {useDevice} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';
import {match} from 'ts-pattern';

import {ChartVariant} from '../types/ChartVariant';

export type Formatter = (value: number) => string;

export function useYAxisTickFormatter(chartVariant: ChartVariant) {
  const formatNumber = useFormatNumber();

  return match<ChartVariant, Formatter>(chartVariant)
    .with('price', () => (value) => formatNumber(Math.round(value / 1000)) ?? '')
    .with('mileage', () => (value) => formatNumber(Math.round(value / 1000)) ?? '')
    .with('daysOnStock', () => (value) => formatNumber(value) ?? '')
    .with('featuresLevel', () => (value) => formatNumber(Math.floor(value)) ?? '')
    .with('priceMap', () => (value) => formatNumber(Math.round(value / 1000)) ?? '')
    .exhaustive();
}

export function useYAxisTickFormatterWithUnitOnMobile(chartVariant: ChartVariant) {
  const formatter = useYAxisTickFormatter(chartVariant);
  const device = useDevice();
  const addUnit = (number: string | null, unit: string) =>
    (number ?? '') + (device === 'mobile' ? unit : '');

  return match(chartVariant)
    .with('price', () => (value: number) => addUnit(formatter(value), ' K'))
    .with('mileage', () => (value: number) => addUnit(formatter(value), ' K'))
    .with('featuresLevel', () => (value: number) => addUnit(formatter(value), ' %'))
    .with('priceMap', () => (value: number) => addUnit(formatter(value), ' K'))
    .otherwise(() => formatter);
}
