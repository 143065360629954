import {useFlagsmith} from 'feature-flags';
import {ComponentsProvider} from 'platform/components';
import {StyleSheetManager} from 'styled-components';

import {useState} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';

import {FLAGSMITH_URL} from '@price-report/config';
import {environment} from '@price-report/environment';
import {FALLBACK_LANGUAGE, i18n} from '@price-report/shared';

import {useOnMount} from 'shared';

import {FilterLayout} from './components/FilterLayout/FilterLayout';
import {Layout} from './components/Layout/Layout';
import {GlobalStyles} from './GlobalStyles';
import {AdvertisementPage} from './pages/AdvertisementPage/AdvertisementPage';
import {CreatePage} from './pages/Create/CreatePage';
import {DrillDownPage} from './pages/DrillDown/DrillDownPage';
import {ErrorPage} from './pages/ErrorPage/ErrorPage';
import {OverviewPage} from './pages/Overview/OverviewPage';
import {StatisticsPage} from './pages/StatisticsPage/StatisticsPage';
import {store} from './store/store';
import {theme} from './theme';
import {
  advertisementRoute,
  createRoute,
  drillDownRoute,
  overviewRoute,
  reportRoute,
  statisticsRoute,
} from './utils/routing';

export function App() {
  const [language, setLanguage] = useState(i18n.resolvedLanguage || FALLBACK_LANGUAGE);
  const {isFlagSmithInitialized} = useFlagsmith({
    apiUrl: FLAGSMITH_URL,
    environmentID: environment.FLAGSMITH_ENV,
  });

  useOnMount(() => {
    const handleLanguageChange = (lang: string) => {
      setLanguage(lang);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  });

  if (!isFlagSmithInitialized) {
    return null;
  }

  const router = createBrowserRouter([
    {
      element: (
        <Provider store={store}>
          <StyleSheetManager enableVendorPrefixes>
            <ComponentsProvider theme={theme} t={i18n.t} language={language}>
              <HelmetProvider>
                <Outlet />
                <GlobalStyles />
              </HelmetProvider>
            </ComponentsProvider>
          </StyleSheetManager>
        </Provider>
      ),

      children: createRoutesFromElements(
        <>
          <Route path={reportRoute} element={<Layout />} caseSensitive>
            <Route path={createRoute} element={<CreatePage data-testid="createPage" />} />
            <Route path={overviewRoute} element={<OverviewPage data-testid="overviewPage" />} />
            <Route path={drillDownRoute} element={<DrillDownPage data-testid="drilldownPage" />} />
            <Route element={<FilterLayout />}>
              <Route
                path={statisticsRoute}
                element={<StatisticsPage data-testid="statisticsPage" />}
              />
              <Route
                path={advertisementRoute}
                element={<AdvertisementPage data-testid="advertisementPage" />}
              />
            </Route>
            <Route path="*" element={<Navigate to={overviewRoute} />} />
            <Route path="" element={<Navigate to={overviewRoute} />} />
          </Route>
          <Route path="*" element={<ErrorPage data-testid="errorPage" />} />
        </>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}
