import {isNotEmpty} from 'ramda';

import {useQueryState} from 'shared';

import {QueryStateKeys} from '../../../../../utils/routing';
import {LevelConfiguration} from '../types';

export const useLevelConfigurationOrderQueryState = (
  defaultLevelConfigurations: LevelConfiguration[]
) => {
  const [order, setOrder] = useQueryState(QueryStateKeys.DrillDownOrder, true);
  const setLevelConfigurations = (configurations: LevelConfiguration[]) => {
    setOrder(configurations.map((configuration) => configuration.name).toString());
  };
  const levelConfigurations =
    (isNotEmpty(order)
      ? (order
          ?.split(',')
          ?.map((levelName) =>
            defaultLevelConfigurations.find((configuration) => configuration.name === levelName)
          )
          ?.filter(Boolean) as LevelConfiguration[])
      : null) ?? defaultLevelConfigurations;

  return [levelConfigurations, setLevelConfigurations] as const;
};
