import {DrillDownLevelName, i18n} from '@price-report/shared';

import {Nullish} from 'shared';

import {LevelConfiguration} from '../types';

type LevelConfigurationBase = Omit<LevelConfiguration, 'title'> & {translationKey: string};

const LEVELS_CONFIGURATIONS: LevelConfigurationBase[] = [
  {
    name: 'make',
    translationKey: 'entity.drillDown.labels.make',
  },
  {
    name: 'model_family_group',
    translationKey: 'entity.drillDown.labels.modelFamily',
  },
  {
    name: 'model',
    translationKey: 'entity.drillDown.labels.model',
  },
  {
    name: 'year',
    translationKey: 'entity.drillDown.labels.year',
  },
  {
    name: 'power',
    translationKey: 'entity.drillDown.labels.power',
    formatter: (value) => `${value} ${i18n.t('general.metric.kW')}`,
  },
  {
    name: 'fuel_type',
    translationKey: 'entity.drillDown.labels.fuel',
  },
  {
    name: 'transmission',
    translationKey: 'entity.drillDown.labels.transmission',
  },
  {
    name: 'drive',
    translationKey: 'entity.drillDown.labels.drive',
  },
  {
    name: 'car_style',
    translationKey: 'entity.drillDown.labels.bodyStyle',
  },
  {
    name: 'mileage_range',
    translationKey: 'entity.drillDown.labels.mileage',
  },
];

export function getLevelConfigurations(
  hiddenLevels: DrillDownLevelName[] | Nullish
): LevelConfiguration[] {
  return LEVELS_CONFIGURATIONS.filter(
    (levelConfiguration) => !hiddenLevels?.includes(levelConfiguration.name)
  ).map((levelConfiguration) => ({
    ...levelConfiguration,
    title: i18n.t(levelConfiguration.translationKey),
  }));
}
