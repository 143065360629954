import {isNotEmpty, isNotNil} from 'ramda';

import {FilterFormData} from '@price-report/shared';

export const filterKeys: (keyof FilterFormData)[] = [
  'showSoldCars',
  'showStockCars',
  'priceGte',
  'priceLte',
  'isVatDeductible',
  'firstRegistrationOnYearGte',
  'firstRegistrationOnYearLte',
  'powerGte',
  'powerLte',
  'mileageGte',
  'mileageLte',
  'drive',
  'transmission',
  'bodyStyle',
  'fuelType',
];

export const parseFilterFormDataToSearchParams = (
  currentSearchParams: URLSearchParams,
  filterFormData: FilterFormData | null
): URLSearchParams => {
  const params = new URLSearchParams(currentSearchParams);
  filterKeys.forEach((key) => {
    if (isNotNil(filterFormData?.[key]) && isNotEmpty(filterFormData[key])) {
      params.set(key, filterFormData[key].toString());
    } else {
      params.delete(key);
    }
  });
  return params;
};
